import * as React from 'react';
import { useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { Button } from 'src/components/Button';

import messageIc from 'src/assets/icons/message.svg';
import location from 'src/assets/icons/location.svg';
import facebook from 'src/assets/icons/facebook-white.svg';
import instagram from 'src/assets/icons/instagram-white.svg';
import linkedin from 'src/assets/icons/linkedin-white.svg';
import form from 'src/assets/icons/form.svg';

const ContactPage = () => {
  const [name, setName] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('Send message');

  const handleSubmitForm = useCallback(e => {
    e.preventDefault();
    var status = document.getElementById("form-status");
    var data = new FormData();
    data.set('name', name);
    data.set('info', info);
    data.set('message', message);
    setName('');
    setInfo('');
    setMessage('');
    setButtonText('Sending...');
    fetch("https://formspree.io/f/xoqyedgl", {
      method: "POST",
      body: data,
      headers: {
          'Accept': 'application/json'
      }
    }).then(response => {
      status.innerHTML = "Thank you for contacting us. We will get back to you as soon as possible.";
      status.classList.add('alert');
      status.classList.add('alert-success');
      setButtonText('Send message');
    }).catch(error => {
      status.innerHTML = "There was a problem submitting your form. Please try again later or email us directly to info@alviso.cz";
      status.classList.add('alert')
      status.classList.add('alert-danger')
      setButtonText('Send message');
    });
    setName('');
    setInfo('');
    setMessage('');
  }, [
    name,
    info,
    message,
  ]);

  return (
    <Layout
      className="contact"
      noFooter
    >
      <SEO title="Contact" />
      <div className="container">
        <PageTitle title="Let's get in touch" className="mb-2 mb-md-3" />
        <p className="mb-2 mb-md-5">We are happy to talk about your future!</p>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="d-flex mb-3 mb-md-4">
              <SVG className="contact__icon" src={messageIc} />
              <div className="d-flex flex-column">
                <p>Michaela Huláková</p>
                <a href="mailto:info@alviso.cz">info@alviso.cz</a>
                <a href="tel:00420739534701">+420 739 534 701</a>
              </div>
            </div>
            <div className="d-flex mb-3 mb-md-5">
              <SVG className="contact__icon" src={location} />
              <div className="d-flex flex-column">
                <p><strong>Alviso Recruitment s.r.o.</strong></p>
                <p>Janáčkovo nábřeží 133/61</p>
                <p className="mb-2 mb-md-4">150 00 Praha 5 - Malá Strana</p>
                <p>IČ: 03614379</p>
                <p>DIČ: CZ03614379</p>
              </div>
            </div>
            <div className="d-flex mb-3 mb-md-5">
              <SVG className="contact__icon" src={location} />
              <div className="d-flex flex-column">
                <p><strong>Alviso Consulting s.r.o.</strong></p>
                <p>Šatrova 529/18</p>
                <p className="mb-2 mb-md-4">142 00 Praha 4 - Libuš</p>
                <p>IČ: 19420161</p>
              </div>
            </div>
            <div className="mb-3">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/alviso/"
              >
                <SVG className="contact__icon social" src={linkedin} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/alvisorecruitment/"
              >
                <SVG className="contact__icon social" src={facebook} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/alviso.cz/"
              >
                <SVG className="contact__icon social" src={instagram} />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex">
              <SVG className="contact__icon d-none d-md-block" src={form} />
              <form className="d-flex flex-column" id="contact-form">
                <div className="d-flex">
                  <SVG className="contact__icon d-md-none mr-2" src={form} />
                  <p className="mb-2">
                    Leave us a message or make a note when you
                    are available and we will get back to you.
                  </p>
                </div>
                <div id="form-status"></div>
                <input
                  className="contact__input"
                  placeholder="Your name"
                  type="text"
                  name="name"
                  required
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
                <input
                  className="contact__input"
                  placeholder="E-mail or phone number"
                  type="text"
                  name="contact"
                  required
                  onChange={e => setInfo(e.target.value)}
                  value={info}
                />
                <textarea
                  className="contact__input"
                  placeholder="Message"
                  name="message"
                  required
                  rows={5}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                />
                <Button
                  className="btn-primary mb-3 px-2"
                  onClick={e => handleSubmitForm(e)}
                  disabled={
                    !name ||
                    !info ||
                    !message
                  }
                >
                  {buttonText}
                </Button>
                <small>
                  By submitting your request you hereby agree with
                  the processing of your personal data in accordance
                  to our <Link to='/privacy'>Privacy Policy</Link>.
                </small>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
